// Cookiefirst
import config from "../config";

export default {

	methods: {
		putCookieFirstScriptInPlace(){
			console.log("--- COOKIEFIRST --------------------------------------");
			console.log("Deploying COOKIEFIRST");
			console.log("-----------------------------------------");
			let script = document.createElement('script');
			script.innerHTML = ``;
			script.setAttribute('src', `https://consent.cookiefirst.com/banner.js`);

			/* Put the code for this action here */
			script.setAttribute('data-cookiefirst-key',`9471e149-8b61-4126-aa59-7b0f4bcc2545`);

			document.body.prepend(script);
		}
	},
	beforeMount() {
		if(config.isWebsiteInProduction && !this.isPnGAction){
			this.putCookieFirstScriptInPlace();
		}
	}

}
